import React from 'react';
import PageCsv from '../components/Csv/PageCsv';

const SPREADSHEET = '1QjjuqTXdP9VW56NUsDoSgQlUetnNskRJl7IGPyGfwSM';
const SHEET_ID = '';
const TITLE = 'MEMORIZE';
const RANGE = 'A3:B';

export default function Page () {
  const RANGE_COMPLETE = SHEET_ID ? `${SHEET_ID}!${RANGE}` : RANGE;
  return (
    <PageCsv
      title={TITLE}
      spreadsheetId={SPREADSHEET}
      range={RANGE_COMPLETE}
    />
  )

}
